<template>
  <div class="login-container">
    <div class="top_logo">
      <!-- <img class="logo_img" src="@/assets/logo.png" alt=""> -->
    </div>
    <div class="background_box">
      <div class="right_form">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="rules"
          class="login-form"
          auto-complete="on"
          label-position="left"
        >
          <div class="title-container">
            <h3 class="title">ops管理</h3>
          </div>

          <el-form-item prop="username">
            <span class="svg-container">
              <!-- <el-icon><avatar /></el-icon> -->
              <i class="el-icon-user"></i>
              <svg-icon icon-class="user" />
            </span>
            <el-input
              v-model="loginForm.username"
              placeholder="用户名"
              name="username"
              type="text"
              class="tips"
              auto-complete="on"
              @keyup.enter.native="handleLogin"
            />
          </el-form-item>

          <el-form-item prop="password">
            <span class="svg-container">
              <i class="el-icon-lock"></i>
              <svg-icon icon-class="password" />
            </span>
            <el-input
              :type="passwordType"
              v-model="loginForm.password"
              placeholder="密码"
              name="password"
              auto-complete="on"
              class="tips"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <svg-icon icon-class="eye" />
            </span>
          </el-form-item>

          <el-button
            size="mini"
            :loading="loading"
            type="primary"
            class="login_btn"
            @click.native.prevent="handleLogin"
            >登录</el-button
          >
        </el-form>
      </div>
    </div>
    <div class="bg"></div>
  </div>
</template>

<script>
import { loginByUsername, logout, getUserInfo } from "@/api/login";
import { getToken, removeToken, setToken } from "@/utils/auth";
import axios from "axios";
export default {
  name: "login",
  data() {
    return {
      passwordType: "password",
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleLogin() {
      //   this.$refs['loginForm'].validate((valid) => {
      //     if(this.loginForm.username !='cs' || this.loginForm.password != 'a111111'){
      //       this.$message.error('密码错误')
      //       return
      //     }
      //     if (valid) {
      //       console.log(valid)
      //       this.$store.dispatch('user/LoginByUsername', this.loginForm).then(() => {
      //         // this.$router.push({ path: this.redirect || '/' })
      //         let time = new Date().getTime();
      //         localStorage.setItem("time", time);
      //         this.$router.push({ path: this.redirect || '/' })
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //         this.$message.error(err)
      //       })
      //   } else {
      //     // console.log('error submit!!')
      //     return false
      //   }
      // })
      let data = { password: this.loginForm.password, username: this.loginForm.username };
      axios.post("https://ops.listensoft.net/apiOps/public/login", data).then((res) => {
        if (res.data.errNo == 0) {
          setToken(res.data.data.token);
          let time = new Date().getTime();
          localStorage.setItem("time", time);
          this.$router.push({ path: this.redirect || '/' })
        }else{
          this.$message.error(err)
        }
      });
      // let token = "xxxxx";
      // setToken(token);
      // loginByUsername({
      //   password: this.loginForm.password,
      //   username: this.loginForm.username,
      // }).then((res) => {
      //   console.log(res);
      // });
    },
  },
  setup() {
    return {};
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */
// $bg:#283443;
// $light_gray:#eee;
// $cursor: #999;
// @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
//   .login-container .el-input input{
//     color: $cursor;
//     &::first-line {
//       color: $light_gray;
//     }
//   }
// }

$bg: #eee;
$light_gray: #666;
$cursor: #999;
@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
    &::first-line {
      color: $light_gray;
    }
  }
}

/* reset element-ui css */
.login-container {
  width: 100%;
  height: 100%;
  // position: relative;
  .top_logo {
    position: absolute;
    width: 100%;
    height: 68px;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    // background-color: rgba(106,191,186,.6);
    padding: 0 40px;
    z-index: 20;
    img {
      float: left;
      width: 161px;
      height: 40px;
    }
  }
  .background_box {
    position: relative;
    min-height: 100%;
    width: 100%;
    height: 100%;
    background: url(../../assets/bg_img.png) no-repeat bottom;
    background-size: 100%;
    overflow: hidden;
    position: relative;
    z-index: 2;
    padding-bottom: 50px;
    .right_form {
      background-color: #fff;
      position: absolute;
      border-radius: 10px;
      width: 420px;
      z-index: 20;
      height: 450px;
      max-width: 100%;
      padding: 85px 40px 5px;
      right: 10%;
      top: 22%;
      box-shadow: 18px 10px 30px 0 #6abfba;
      overflow: hidden;
      .login_btn {
        width: 100%;
        margin-bottom: 30px;
        border-radius: 50px !important;
        line-height: 36px !important;
        height: 36px !important;
      }
    }
  }
  .bg {
    min-height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#987ade),
      to(#2ed9ca)
    );
    background-image: linear-gradient(140deg, #987ade, #2ed9ca);
  }
  .el-input {
    display: inline-block;
    height: 36px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 0 10px;
      color: #333;
      height: 33px;
      line-height: 36px;
      padding: 0 !important;
      caret-color: $cursor;
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #eee;
    border-radius: 50px;
    color: #454545;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px !important;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;
  .login-form {
    width: 100%;
    margin: 0 auto;
  }
  .tips {
    font-size: 13px;
    color: #666;
    // margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 0 5px 0 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    // display: inline-block;
    font-size: 17px;
  }
  .title-container {
    position: relative;
    .title {
      font-size: 26px;
      color: #6abfba;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
    .set-language {
      color: #fff;
      position: absolute;
      top: 5px;
      right: 0px;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .thirdparty-button {
    position: absolute;
    right: 35px;
    bottom: 28px;
  }
}
</style>
